
import { Component, Vue } from "vue-property-decorator";
import { products } from "@/services/api/products.service";
import { ProductsCard } from "@/models/entities/products-card.interface";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import ProductDetailsModule from "@/store/modules/productdetails.module";
import SnackbarModule from "@/store/modules/snackbar.module";

const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);
const productDetailsModule = getModule(ProductDetailsModule, store);

@Component
export default class ResFavorites extends Vue {
  private search: string = null;
  private products: ProductsCard[] = [];

  private created(): void {
    this.getData();
  }

  private async getData(): Promise<void> {
    await this.getFavoriteProducts();
  }

  private async getFavoriteProducts(): Promise<void> {
    overlayModule.showOverlay();
    const p = await products.getProductsFavorite(null, this.search);
    this.products = p.data;
  }

  private goToDetails(id: number) {
    let productFound = this.products.find(
      (product: ProductsCard) => product.id === id
    );
    if (productFound) {
      productDetailsModule.changeProduct(productFound);
      this.$router.push({ name: "details" });
    } else {
      snackbarModule.showSnackbar({
        message: "Prodotto non trovato, prova a ricaricare la pagina",
        type: "error",
      });
    }
  }

  private navHome(): void {
    this.$router.push({ name: "home" });
  }
}
